import { useEffect } from "react"
import {
  addIndexDBStore,
  addToIndexDBWithQueue,
  initIndexDB,
  readFromIndexDB,
} from "../utils/indexDB"
import useApartmentStore from "./useApartmentStore"
import {
  formFieldsName,
  formStateName,
  inspectionCreationFormName,
} from "../utils/constants"

const useInspectionIndexDB = () => {
  const { inspectionsStore, apartmentInfo } = useApartmentStore()
  const { creationForm, actionLoader, indexDB, setCanUpdateDB, canUpdateDB } =
    inspectionsStore

  const dbName = inspectionCreationFormName

  const onupgradeneeded = (db: any) => {
    addIndexDBStore(db, formFieldsName)
    addIndexDBStore(db, formStateName)
  }

  const initInspectionIndexDB = async () => {
    initIndexDB(dbName, onupgradeneeded, actionLoader, async (db) => {
      inspectionsStore.setIndexDB(db)

      setCanUpdateDB(false)

      const formFields = await readFromIndexDB(db, formFieldsName, actionLoader)

      if (formFields) {
        if (formFields?.apartmentId === apartmentInfo?.id) {
          creationForm.updateFormFields(formFields)
        }
      } else {
        setCanUpdateDB(true)
      }
    })
  }

  const initFormState = async () => {
    const formState = await readFromIndexDB(
      indexDB,
      formStateName,
      actionLoader,
    )
    const formFields = await readFromIndexDB(
      indexDB,
      formFieldsName,
      actionLoader,
    )

    if (formState && formFields?.apartmentId === apartmentInfo?.id) {
      creationForm.setStep(Number(formState.step))
    }

    setCanUpdateDB(true)
  }

  useEffect(() => {
    if (apartmentInfo?.id) initInspectionIndexDB()
  }, [apartmentInfo?.id])

  useEffect(() => {
    if (inspectionsStore.canLoadDB && indexDB) {
      initFormState()
    }
  }, [inspectionsStore.canLoadDB, indexDB])

  useEffect(() => {
    if (canUpdateDB) {
      const formState = {
        step: creationForm.step,
      }
      addToIndexDBWithQueue(indexDB, formStateName, formState, actionLoader)

      addToIndexDBWithQueue(
        indexDB,
        formFieldsName,
        creationForm.fields,
        actionLoader,
      )
    }
  }, [creationForm.step])
}

export default useInspectionIndexDB
